// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";

function billingDetailsTuple(fullName, email, line1, line2, city, state, postalCode, country) {
  var match = Utils$OrcaPaymentPage.getFirstAndLastNameFromFullName(fullName);
  return [
          "billing",
          Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                [
                  "email",
                  email
                ],
                [
                  "address",
                  Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                        [
                          "first_name",
                          match[0]
                        ],
                        [
                          "last_name",
                          match[1]
                        ],
                        [
                          "line1",
                          line1
                        ],
                        [
                          "line2",
                          line2
                        ],
                        [
                          "city",
                          city
                        ],
                        [
                          "state",
                          state
                        ],
                        [
                          "zip",
                          postalCode
                        ],
                        [
                          "country",
                          country
                        ]
                      ])
                ]
              ])
        ];
}

function cardPaymentBody(cardNumber, month, year, cardHolderNameOpt, cvcNumber, cardBrand, nicknameOpt) {
  var cardHolderName = cardHolderNameOpt !== undefined ? Caml_option.valFromOption(cardHolderNameOpt) : undefined;
  var nickname = nicknameOpt !== undefined ? nicknameOpt : "";
  var cardBody = [
    [
      "card_number",
      CardUtils$OrcaPaymentPage.clearSpaces(cardNumber)
    ],
    [
      "card_exp_month",
      month
    ],
    [
      "card_exp_year",
      year
    ],
    [
      "card_cvc",
      cvcNumber
    ],
    [
      "card_issuer",
      ""
    ]
  ];
  Core__Option.map(cardHolderName, (function (name) {
          cardBody.push([
                "card_holder_name",
                name
              ]);
        }));
  if (nickname !== "") {
    cardBody.push([
          "nick_name",
          nickname
        ]);
  }
  return [
          [
            "payment_method",
            "card"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "card",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson(cardBody.concat(cardBrand))
                  ]])
          ]
        ];
}

function bancontactBody() {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "bancontact_card"
          ]
        ];
}

function boletoBody(socialSecurityNumber) {
  return [
          [
            "payment_method",
            "voucher"
          ],
          [
            "payment_method_type",
            "boleto"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "voucher",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "boleto",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "social_security_number",
                                    socialSecurityNumber
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

var customerAcceptanceBody = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
      [
        "acceptance_type",
        "online"
      ],
      [
        "accepted_at",
        new Date(Date.now()).toISOString()
      ],
      [
        "online",
        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                "user_agent",
                navigator.userAgent
              ]])
      ]
    ]);

function savedCardBody(paymentToken, customerId, cvcNumber, requiresCvv, isCustomerAcceptanceRequired) {
  var savedCardBody$1 = [
    [
      "payment_method",
      "card"
    ],
    [
      "payment_token",
      paymentToken
    ],
    [
      "customer_id",
      customerId
    ]
  ];
  if (requiresCvv) {
    savedCardBody$1.push([
          "card_cvc",
          cvcNumber
        ]);
  }
  if (isCustomerAcceptanceRequired) {
    savedCardBody$1.push([
          "customer_acceptance",
          customerAcceptanceBody
        ]);
  }
  return savedCardBody$1;
}

function clickToPayBody(merchantTransactionId, correlationId, xSrcFlowId) {
  var clickToPayServiceDetails = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
        [
          "merchant_transaction_id",
          merchantTransactionId
        ],
        [
          "correlation_id",
          correlationId
        ],
        [
          "x_src_flow_id",
          xSrcFlowId
        ]
      ]);
  return [
          [
            "payment_method",
            "card"
          ],
          [
            "ctp_service_details",
            clickToPayServiceDetails
          ]
        ];
}

function savedPaymentMethodBody(paymentToken, customerId, paymentMethod, paymentMethodType, isCustomerAcceptanceRequired) {
  var savedPaymentMethodBody$1 = [
    [
      "payment_method",
      paymentMethod
    ],
    [
      "payment_token",
      paymentToken
    ],
    [
      "customer_id",
      customerId
    ],
    [
      "payment_method_type",
      paymentMethodType
    ]
  ];
  if (isCustomerAcceptanceRequired) {
    savedPaymentMethodBody$1.push([
          "customer_acceptance",
          customerAcceptanceBody
        ]);
  }
  return savedPaymentMethodBody$1;
}

function mandateBody(paymentType) {
  return [
          [
            "mandate_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "customer_acceptance",
                    customerAcceptanceBody
                  ]])
          ],
          [
            "customer_acceptance",
            customerAcceptanceBody
          ],
          [
            "setup_future_usage",
            "off_session"
          ],
          [
            "payment_type",
            paymentType === "" ? null : paymentType
          ]
        ];
}

function paymentTypeBody(paymentType) {
  if (paymentType !== "") {
    return [[
              "payment_type",
              paymentType
            ]];
  } else {
    return [];
  }
}

function confirmPayloadForSDKButton(sdkHandleConfirmPayment) {
  return Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                "confirmParams",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                      [
                        "return_url",
                        sdkHandleConfirmPayment.confirmParams.return_url
                      ],
                      [
                        "redirect",
                        "always"
                      ]
                    ])
              ]]);
}

function bankDebitsCommonBody(paymentMethodType) {
  return [
          [
            "payment_method",
            "bank_debit"
          ],
          [
            "setup_future_usage",
            "off_session"
          ],
          [
            "payment_method_type",
            paymentMethodType
          ],
          [
            "customer_acceptance",
            customerAcceptanceBody
          ]
        ];
}

function achBankDebitBody(email, bank, cardHolderName, line1, line2, country, city, postalCode, state) {
  return bankDebitsCommonBody("ach").concat([[
                "payment_method_data",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                      billingDetailsTuple(cardHolderName, email, line1, line2, city, state, postalCode, country),
                      [
                        "bank_debit",
                        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                "ach_bank_debit",
                                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                      [
                                        "account_number",
                                        bank.accountNumber
                                      ],
                                      [
                                        "bank_account_holder_name",
                                        bank.accountHolderName
                                      ],
                                      [
                                        "routing_number",
                                        bank.routingNumber
                                      ],
                                      [
                                        "bank_type",
                                        bank.accountType
                                      ]
                                    ])
                              ]])
                      ]
                    ])
              ]]);
}

function bacsBankDebitBody(email, accNum, sortCode, line1, line2, city, zip, state, country, bankAccountHolderName) {
  return bankDebitsCommonBody("bacs").concat([[
                "payment_method_data",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                      billingDetailsTuple(bankAccountHolderName, email, line1, line2, city, state, zip, country),
                      [
                        "bank_debit",
                        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                "bacs_bank_debit",
                                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                      [
                                        "bank_account_holder_name",
                                        bankAccountHolderName
                                      ],
                                      [
                                        "sort_code",
                                        sortCode
                                      ],
                                      [
                                        "account_number",
                                        accNum
                                      ]
                                    ])
                              ]])
                      ]
                    ])
              ]]);
}

function becsBankDebitBody(fullName, email, data, line1, line2, country, city, postalCode, state) {
  return bankDebitsCommonBody("becs").concat([[
                "payment_method_data",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                      billingDetailsTuple(fullName, email, line1, line2, city, state, postalCode, country),
                      [
                        "bank_debit",
                        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                "becs_bank_debit",
                                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                      [
                                        "bsb_number",
                                        data.sortCode
                                      ],
                                      [
                                        "account_number",
                                        data.accountNumber
                                      ],
                                      [
                                        "bank_account_holder_name",
                                        data.accountHolderName
                                      ]
                                    ])
                              ]])
                      ]
                    ])
              ]]);
}

function klarnaRedirectionBody(fullName, email, country, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "name",
            fullName
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "pay_later",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "klarna_redirect",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "billing_email",
                                    email
                                  ],
                                  [
                                    "billing_country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function klarnaSDKbody(token, connectors) {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "pay_later",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "klarna_sdk",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function klarnaCheckoutBody(connectors) {
  var checkoutBody = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([]);
  var payLaterBody = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "klarna_checkout",
          checkoutBody
        ]]);
  var paymentMethodData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "pay_later",
          payLaterBody
        ]]);
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "klarna"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            paymentMethodData
          ]
        ];
}

function paypalRedirectionBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "paypal_redirect",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([])
                          ]])
                  ]])
          ]
        ];
}

function paypalSdkBody(token, connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paypal"
          ],
          [
            "payment_experience",
            "invoke_sdk_client"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "paypal_sdk",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "token",
                                    token
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function samsungPayBody(metadata) {
  var paymentCredential = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "payment_credential",
          metadata
        ]]);
  var spayBody = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "samsung_pay",
          paymentCredential
        ]]);
  var paymentMethodData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "wallet",
          spayBody
        ]]);
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "samsung_pay"
          ],
          [
            "payment_method_data",
            paymentMethodData
          ]
        ];
}

function gpayBody(payObj, connectors) {
  var gPayBody = [
    [
      "payment_method",
      "wallet"
    ],
    [
      "payment_method_type",
      "google_pay"
    ],
    [
      "payment_method_data",
      Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
              "wallet",
              Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                      "google_pay",
                      Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                            [
                              "type",
                              payObj.paymentMethodData.type
                            ],
                            [
                              "description",
                              payObj.paymentMethodData.description
                            ],
                            [
                              "info",
                              Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.info, "SnakeCase")
                            ],
                            [
                              "tokenization_data",
                              Utils$OrcaPaymentPage.transformKeys(payObj.paymentMethodData.tokenizationData, "SnakeCase")
                            ]
                          ])
                    ]])
            ]])
    ]
  ];
  if (connectors.length > 0) {
    gPayBody.push([
          "connector",
          Utils$OrcaPaymentPage.getArrofJsonString(connectors)
        ]);
  }
  return gPayBody;
}

function gpayRedirectBody(connectors) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "google_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function gPayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "google_pay"
          ],
          [
            "payment_method_data",
            Object.fromEntries([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "google_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayBody(token, connectors) {
  var dict = Core__Option.getOr(Core__JSON.Decode.object(token), {});
  var paymentDataString = window.btoa(JSON.stringify(Core__Option.getOr(dict["paymentData"], {})));
  dict["paymentData"] = paymentDataString;
  var applePayBody$1 = [
    [
      "payment_method",
      "wallet"
    ],
    [
      "payment_method_type",
      "apple_pay"
    ],
    [
      "payment_method_data",
      Object.fromEntries([[
              "wallet",
              Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                      "apple_pay",
                      Utils$OrcaPaymentPage.transformKeys(dict, "SnakeCase")
                    ]])
            ]])
    ]
  ];
  if (connectors.length > 0) {
    applePayBody$1.push([
          "connector",
          Utils$OrcaPaymentPage.getArrofJsonString(connectors)
        ]);
  }
  return applePayBody$1;
}

function applePayRedirectBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "apple_pay_redirect",
                            {}
                          ]])
                  ]])
          ]
        ];
}

function applePayThirdPartySdkBody(connectors) {
  return [
          [
            "connector",
            Utils$OrcaPaymentPage.getArrofJsonString(connectors)
          ],
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "apple_pay"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "apple_pay_third_party_sdk",
                            {}
                          ]])
                  ]])
          ]
        ];
}

var cryptoBody = [
  [
    "payment_method",
    "crypto"
  ],
  [
    "payment_method_type",
    "crypto_currency"
  ],
  [
    "payment_experience",
    "redirect_to_url"
  ],
  [
    "payment_method_data",
    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([])
  ]
];

function afterpayRedirectionBody() {
  return [
          [
            "payment_method",
            "pay_later"
          ],
          [
            "payment_method_type",
            "afterpay_clearpay"
          ],
          [
            "payment_experience",
            "redirect_to_url"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "pay_later",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "afterpay_clearpay_redirect",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([])
                          ]])
                  ]])
          ]
        ];
}

function giroPayBody(name, ibanOpt) {
  var iban = ibanOpt !== undefined ? ibanOpt : "";
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "giropay"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "giropay",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "billing_details",
                                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_account_iban",
                                    iban
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function sofortBody(country, name, email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "sofort"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "sofort",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "country",
                                    country === "" ? "US" : country
                                  ],
                                  [
                                    "preferred_language",
                                    "en"
                                  ],
                                  [
                                    "billing_details",
                                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                          [
                                            "billing_name",
                                            name
                                          ],
                                          [
                                            "email",
                                            email === "" ? "test@gmail.com" : email
                                          ]
                                        ])
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function iDealBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "ideal"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "ideal",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "billing_details",
                                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function epsBody(name, bankName) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "eps"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "eps",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "billing_details",
                                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                            "billing_name",
                                            name
                                          ]])
                                  ],
                                  [
                                    "bank_name",
                                    bankName === "" ? "american_express" : bankName
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function blikBody(blikCode) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "blik"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "blik",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "blik_code",
                                    blikCode
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function p24Body(email) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "przelewy24"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                  [
                    "billing",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "email",
                            email
                          ]])
                  ],
                  [
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "przelewy24",
                            {}
                          ]])
                  ]
                ])
          ]
        ];
}

function interacBody(email, country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "interac"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "interac",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
                                  [
                                    "email",
                                    email
                                  ],
                                  [
                                    "country",
                                    country
                                  ]
                                ])
                          ]])
                  ]])
          ]
        ];
}

function trustlyBody(country) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "trustly"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "trustly",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "country",
                                    country
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function polandOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_poland"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "online_banking_poland",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function czechOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_czech_republic"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "online_banking_czech_republic",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function slovakiaOB(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_slovakia"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "online_banking_slovakia",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function mbWayBody(phoneNumber) {
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "mb_way"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "wallet",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "mb_way_redirect",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "telephone_number",
                                    phoneNumber
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function rewardBody(paymentMethodType) {
  return [
          [
            "payment_method",
            "reward"
          ],
          [
            "payment_method_type",
            paymentMethodType
          ],
          [
            "payment_method_data",
            "reward"
          ]
        ];
}

function fpxOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_fpx"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "online_banking_fpx",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function thailandOBBody(bank) {
  return [
          [
            "payment_method",
            "bank_redirect"
          ],
          [
            "payment_method_type",
            "online_banking_thailand"
          ],
          [
            "payment_method_data",
            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                    "bank_redirect",
                    Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                            "online_banking_thailand",
                            Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                    "issuer",
                                    bank
                                  ]])
                          ]])
                  ]])
          ]
        ];
}

function pazeBody(completeResponse) {
  var pazeCompleteResponse = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "complete_response",
          completeResponse
        ]]);
  var pazeWalletData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "paze",
          pazeCompleteResponse
        ]]);
  var paymentMethodData = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
          "wallet",
          pazeWalletData
        ]]);
  return [
          [
            "payment_method",
            "wallet"
          ],
          [
            "payment_method_type",
            "paze"
          ],
          [
            "payment_method_data",
            paymentMethodData
          ]
        ];
}

function getPaymentMethodType(paymentMethod, paymentMethodType) {
  switch (paymentMethod) {
    case "bank_debit" :
        return paymentMethodType.replace("_debit", "");
    case "bank_transfer" :
        return paymentMethodType.replace("_transfer", "");
    default:
      return paymentMethodType;
  }
}

var appendRedirectPaymentMethods = [
  "touch_n_go",
  "momo",
  "gcash",
  "kakao_pay",
  "go_pay",
  "dana",
  "vipps",
  "twint",
  "atome",
  "pay_bright",
  "walley",
  "affirm",
  "we_chat_pay",
  "ali_pay",
  "ali_pay_hk"
];

var appendBankeDebitMethods = ["sepa"];

var appendBankTransferMethods = [
  "sepa",
  "ach",
  "bacs",
  "multibanco"
];

function getPaymentMethodSuffix(paymentMethodType, paymentMethod, isQrPaymentMethod) {
  if (isQrPaymentMethod) {
    return "qr";
  } else if (appendRedirectPaymentMethods.includes(paymentMethodType)) {
    return "redirect";
  } else if (appendBankeDebitMethods.includes(paymentMethodType) && paymentMethod === "bank_debit") {
    return "bank_debit";
  } else if (appendBankTransferMethods.includes(paymentMethodType) && paymentMethod === "bank_transfer") {
    return "bank_transfer";
  } else {
    return ;
  }
}

function appendPaymentMethodExperience(paymentMethod, paymentMethodType, isQrPaymentMethod) {
  var suffix = getPaymentMethodSuffix(paymentMethodType, paymentMethod, isQrPaymentMethod);
  if (suffix !== undefined) {
    return paymentMethodType + "_" + suffix;
  } else {
    return paymentMethodType;
  }
}

var paymentExperiencePaymentMethods = ["affirm"];

function appendPaymentExperience(paymentBodyArr, paymentMethodType) {
  if (paymentExperiencePaymentMethods.includes(paymentMethodType)) {
    return paymentBodyArr.concat([[
                  "payment_experience",
                  "redirect_to_url"
                ]]);
  } else {
    return paymentBodyArr;
  }
}

function dynamicPaymentBody(paymentMethod, paymentMethodType, isQrPaymentMethodOpt) {
  var isQrPaymentMethod = isQrPaymentMethodOpt !== undefined ? isQrPaymentMethodOpt : false;
  var paymentMethodType$1 = getPaymentMethodType(paymentMethod, paymentMethodType);
  return appendPaymentExperience([
              [
                "payment_method",
                paymentMethod
              ],
              [
                "payment_method_type",
                paymentMethodType$1
              ],
              [
                "payment_method_data",
                Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                        paymentMethod,
                        Utils$OrcaPaymentPage.getJsonFromArrayOfJson([[
                                appendPaymentMethodExperience(paymentMethod, paymentMethodType$1, isQrPaymentMethod),
                                {}
                              ]])
                      ]])
              ]
            ], paymentMethodType$1);
}

function getPaymentBody(paymentMethod, paymentMethodType, fullName, email, country, bank, blikCode, paymentExperienceOpt, phoneNumber) {
  var paymentExperience = paymentExperienceOpt !== undefined ? paymentExperienceOpt : "RedirectToURL";
  switch (paymentMethodType) {
    case "afterpay_clearpay" :
        return afterpayRedirectionBody();
    case "ali_pay" :
    case "ali_pay_hk" :
        break;
    case "blik" :
        return blikBody(blikCode);
    case "crypto_currency" :
        return cryptoBody;
    case "duit_now" :
        switch (paymentExperience) {
          case "InvokeSDK" :
          case "RedirectToURL" :
              return dynamicPaymentBody(paymentMethod, paymentMethodType, undefined);
          case "QrFlow" :
              return dynamicPaymentBody(paymentMethod, paymentMethodType, true);
          
        }
    case "eps" :
        return epsBody(fullName, bank);
    case "classic" :
    case "evoucher" :
        return rewardBody(paymentMethodType);
    case "giropay" :
        return giroPayBody(fullName, undefined);
    case "ideal" :
        return iDealBody(fullName, bank);
    case "interac" :
        return interacBody(email, country);
    case "mb_way" :
        return mbWayBody(phoneNumber);
    case "online_banking_czech_republic" :
        return czechOB(bank);
    case "online_banking_fpx" :
        return fpxOBBody(bank);
    case "online_banking_poland" :
        return polandOB(bank);
    case "online_banking_slovakia" :
        return slovakiaOB(bank);
    case "online_banking_thailand" :
        return thailandOBBody(bank);
    case "przelewy24" :
        return p24Body(email);
    case "sofort" :
        return sofortBody(country, fullName, email);
    case "trustly" :
        return trustlyBody(country);
    case "we_chat_pay" :
        switch (paymentExperience) {
          case "InvokeSDK" :
          case "RedirectToURL" :
              return dynamicPaymentBody(paymentMethod, paymentMethodType, undefined);
          case "QrFlow" :
              return dynamicPaymentBody(paymentMethod, paymentMethodType, true);
          
        }
    default:
      return dynamicPaymentBody(paymentMethod, paymentMethodType, undefined);
  }
  switch (paymentExperience) {
    case "InvokeSDK" :
    case "RedirectToURL" :
        return dynamicPaymentBody(paymentMethod, paymentMethodType, undefined);
    case "QrFlow" :
        return dynamicPaymentBody(paymentMethod, paymentMethodType, true);
    
  }
}

export {
  billingDetailsTuple ,
  cardPaymentBody ,
  bancontactBody ,
  boletoBody ,
  customerAcceptanceBody ,
  savedCardBody ,
  clickToPayBody ,
  savedPaymentMethodBody ,
  mandateBody ,
  paymentTypeBody ,
  confirmPayloadForSDKButton ,
  bankDebitsCommonBody ,
  achBankDebitBody ,
  bacsBankDebitBody ,
  becsBankDebitBody ,
  klarnaRedirectionBody ,
  klarnaSDKbody ,
  klarnaCheckoutBody ,
  paypalRedirectionBody ,
  paypalSdkBody ,
  samsungPayBody ,
  gpayBody ,
  gpayRedirectBody ,
  gPayThirdPartySdkBody ,
  applePayBody ,
  applePayRedirectBody ,
  applePayThirdPartySdkBody ,
  cryptoBody ,
  afterpayRedirectionBody ,
  giroPayBody ,
  sofortBody ,
  iDealBody ,
  epsBody ,
  blikBody ,
  p24Body ,
  interacBody ,
  trustlyBody ,
  polandOB ,
  czechOB ,
  slovakiaOB ,
  mbWayBody ,
  rewardBody ,
  fpxOBBody ,
  thailandOBBody ,
  pazeBody ,
  getPaymentMethodType ,
  appendRedirectPaymentMethods ,
  appendBankeDebitMethods ,
  appendBankTransferMethods ,
  getPaymentMethodSuffix ,
  appendPaymentMethodExperience ,
  paymentExperiencePaymentMethods ,
  appendPaymentExperience ,
  dynamicPaymentBody ,
  getPaymentBody ,
}
/* customerAcceptanceBody Not a pure module */
