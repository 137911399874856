// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as PhoneNumberPaymentInput$OrcaPaymentPage from "../Components/PhoneNumberPaymentInput.bs.js";

function getPaymentMethodsFieldsOrder(paymentMethodField) {
  if (typeof paymentMethodField !== "object") {
    switch (paymentMethodField) {
      case "InfoElement" :
          return 99;
      case "AddressLine1" :
          return 4;
      case "AddressLine2" :
          return 5;
      case "AddressCity" :
          return 6;
      case "StateAndCity" :
      case "AddressState" :
          return 7;
      case "CardNumber" :
          return 0;
      case "CardExpiryMonth" :
      case "CardExpiryYear" :
      case "CardExpiryMonthAndYear" :
          return 1;
      case "CardCvc" :
      case "CardExpiryAndCvc" :
          return 2;
      case "PixKey" :
          return 8;
      case "AddressPincode" :
      case "PixCPF" :
          return 9;
      case "CryptoCurrencyNetworks" :
      case "PixCNPJ" :
          return 10;
      default:
        return 3;
    }
  } else {
    switch (paymentMethodField.TAG) {
      case "CountryAndPincode" :
      case "AddressCountry" :
          return 8;
      default:
        return 3;
    }
  }
}

function sortPaymentMethodFields(firstPaymentMethodField, secondPaymentMethodField) {
  return getPaymentMethodsFieldsOrder(firstPaymentMethodField) - getPaymentMethodsFieldsOrder(secondPaymentMethodField) | 0;
}

var defaultPaymentFieldsInfo_fields = [];

var defaultPaymentFieldsInfo = {
  paymentMethodName: "",
  fields: defaultPaymentFieldsInfo_fields,
  icon: undefined,
  displayName: "",
  miniIcon: undefined
};

var defaultPaymentMethodContent_fields = [];

var defaultPaymentMethodContent_paymentFlow = [];

var defaultPaymentMethodContent_bankNames = [];

var defaultPaymentMethodContent = {
  paymentMethodName: "",
  fields: defaultPaymentMethodContent_fields,
  paymentFlow: defaultPaymentMethodContent_paymentFlow,
  handleUserError: false,
  methodType: "",
  bankNames: defaultPaymentMethodContent_bankNames
};

var defaultPaymentMethodFields_fields = [];

var defaultPaymentMethodFields = {
  paymentMethodName: "",
  fields: defaultPaymentMethodFields_fields,
  icon: undefined,
  displayName: "",
  miniIcon: undefined
};

function icon(sizeOpt, widthOpt, name) {
  var size = sizeOpt !== undefined ? sizeOpt : 22;
  var width = widthOpt !== undefined ? widthOpt : size;
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: name,
              size: size,
              width: width
            });
}

var paymentMethodsFields = [
  {
    paymentMethodName: "afterpay_clearpay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "afterpay")),
    displayName: "After Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "google_pay",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "google_pay")),
    displayName: "Google Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "apple_pay",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "apple_pay")),
    displayName: "Apple Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "mb_way",
    fields: [
      {
        TAG: "SpecialField",
        _0: React.createElement(PhoneNumberPaymentInput$OrcaPaymentPage.make, {})
      },
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "mbway")),
    displayName: "Mb Way",
    miniIcon: undefined
  },
  {
    paymentMethodName: "mobile_pay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "mobilepay")),
    displayName: "Mobile Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ali_pay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "alipay")),
    displayName: "Ali Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ali_pay_hk",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "alipayhk")),
    displayName: "Ali Pay HK",
    miniIcon: undefined
  },
  {
    paymentMethodName: "we_chat_pay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "wechatpay")),
    displayName: "WeChat",
    miniIcon: undefined
  },
  {
    paymentMethodName: "duit_now",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(20, undefined, "duitNow")),
    displayName: "DuitNow",
    miniIcon: undefined
  },
  {
    paymentMethodName: "affirm",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(20, undefined, "affirm")),
    displayName: "Affirm",
    miniIcon: undefined
  },
  {
    paymentMethodName: "crypto_currency",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "crypto")),
    displayName: "Crypto",
    miniIcon: undefined
  },
  {
    paymentMethodName: "card",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "klarna",
    fields: [
      "Email",
      "FullName",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "klarna")),
    displayName: "Klarna",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sofort",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "sofort")),
    displayName: "Sofort",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bacs_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "bank")),
    displayName: "BACS Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sepa_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "sepa")),
    displayName: "SEPA Bank Transfer",
    miniIcon: undefined
  },
  {
    paymentMethodName: "sepa_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "sepa")),
    displayName: "SEPA Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "giropay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "giropay")),
    displayName: "GiroPay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "eps",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "eps")),
    displayName: "EPS",
    miniIcon: undefined
  },
  {
    paymentMethodName: "walley",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "walley")),
    displayName: "Walley",
    miniIcon: undefined
  },
  {
    paymentMethodName: "pay_bright",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "paybright")),
    displayName: "Pay Bright",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_debit",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bacs_debit",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BACS Debit",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "becs_debit",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BECS Debit",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "blik",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "blik")),
    displayName: "Blik",
    miniIcon: undefined
  },
  {
    paymentMethodName: "trustly",
    fields: [
      "Country",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, 25, "trustly")),
    displayName: "Trustly",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bancontact_card",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "bancontact")),
    displayName: "Bancontact Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_czech_republic",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking CzechR",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_slovakia",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Slovakia",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_finland",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Finland",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_poland",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Poland",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ideal",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 25, "ideal")),
    displayName: "iDEAL",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ban_connect",
    fields: [],
    icon: undefined,
    displayName: "Ban Connect",
    miniIcon: undefined
  },
  {
    paymentMethodName: "ach_bank_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "ach-bank-debit")),
    displayName: "ACH Direct Debit",
    miniIcon: undefined
  },
  {
    paymentMethodName: "przelewy24",
    fields: [
      "Email",
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "p24")),
    displayName: "Przelewy24",
    miniIcon: undefined
  },
  {
    paymentMethodName: "interac",
    fields: [
      "Email",
      "Country",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "interac")),
    displayName: "Interac",
    miniIcon: undefined
  },
  {
    paymentMethodName: "twint",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "twint")),
    displayName: "Twint",
    miniIcon: undefined
  },
  {
    paymentMethodName: "vipps",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "vipps")),
    displayName: "Vipps",
    miniIcon: undefined
  },
  {
    paymentMethodName: "dana",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "dana")),
    displayName: "Dana",
    miniIcon: undefined
  },
  {
    paymentMethodName: "go_pay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "go_pay")),
    displayName: "Go Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "kakao_pay",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "kakao_pay")),
    displayName: "Kakao Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "gcash",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "gcash")),
    displayName: "GCash",
    miniIcon: undefined
  },
  {
    paymentMethodName: "momo",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "momo")),
    displayName: "Momo",
    miniIcon: undefined
  },
  {
    paymentMethodName: "touch_n_go",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "touch_n_go")),
    displayName: "Touch N Go",
    miniIcon: undefined
  },
  {
    paymentMethodName: "bizum",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "bizum")),
    displayName: "Bizum",
    miniIcon: undefined
  },
  {
    paymentMethodName: "classic",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 50, "cash_voucher")),
    displayName: "Cash / Voucher",
    miniIcon: Caml_option.some(icon(19, undefined, "cash_voucher"))
  },
  {
    paymentMethodName: "online_banking_fpx",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_fpx")),
    displayName: "Online Banking Fpx",
    miniIcon: undefined
  },
  {
    paymentMethodName: "online_banking_thailand",
    fields: [
      "Bank",
      "InfoElement"
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_thailand")),
    displayName: "Online Banking Thailand",
    miniIcon: undefined
  },
  {
    paymentMethodName: "alma",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "alma")),
    displayName: "Alma",
    miniIcon: undefined
  },
  {
    paymentMethodName: "atome",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "atome")),
    displayName: "Atome",
    miniIcon: undefined
  },
  {
    paymentMethodName: "multibanco_transfer",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "multibanco")),
    displayName: "Multibanco",
    miniIcon: undefined
  },
  {
    paymentMethodName: "card_redirect",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card",
    miniIcon: undefined
  },
  {
    paymentMethodName: "open_banking_uk",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "bank")),
    displayName: "Pay by Bank",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "open_banking_pis",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "bank")),
    displayName: "Open Banking",
    miniIcon: Caml_option.some(icon(19, undefined, "bank"))
  },
  {
    paymentMethodName: "evoucher",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 50, "cash_voucher")),
    displayName: "E-Voucher",
    miniIcon: Caml_option.some(icon(19, undefined, "cash_voucher"))
  },
  {
    paymentMethodName: "pix_transfer",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(26, 40, "pix")),
    displayName: "Pix",
    miniIcon: undefined
  },
  {
    paymentMethodName: "boleto",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(21, 25, "boleto")),
    displayName: "Boleto",
    miniIcon: undefined
  },
  {
    paymentMethodName: "paypal",
    fields: [],
    icon: Caml_option.some(icon(21, 25, "paypal")),
    displayName: "Paypal",
    miniIcon: undefined
  },
  {
    paymentMethodName: "local_bank_transfer_transfer",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, 30, "union-pay")),
    displayName: "Union Pay",
    miniIcon: undefined
  },
  {
    paymentMethodName: "mifinity",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(undefined, undefined, "mifinity")),
    displayName: "Mifinity",
    miniIcon: undefined
  },
  {
    paymentMethodName: "upi_collect",
    fields: ["InfoElement"],
    icon: Caml_option.some(icon(19, undefined, "bhim_upi")),
    displayName: "UPI Collect",
    miniIcon: undefined
  }
];

function getPaymentMethodsFieldTypeFromString(str, isBancontact) {
  switch (str) {
    case "user_address_city" :
        return "AddressCity";
    case "user_address_line1" :
        return "AddressLine1";
    case "user_address_line2" :
        return "AddressLine2";
    case "user_address_pincode" :
        return "AddressPincode";
    case "user_address_state" :
        return "AddressState";
    case "user_bank" :
        return "Bank";
    case "user_billing_name" :
        return "BillingName";
    case "user_blik_code" :
        return "BlikCode";
    case "user_card_cvc" :
        if (isBancontact) {
          return "CardCvc";
        } else {
          return "None";
        }
    case "user_card_expiry_month" :
        if (isBancontact) {
          return "CardExpiryMonth";
        } else {
          return "None";
        }
    case "user_card_expiry_year" :
        if (isBancontact) {
          return "CardExpiryYear";
        } else {
          return "None";
        }
    case "user_card_number" :
        if (isBancontact) {
          return "CardNumber";
        } else {
          return "None";
        }
    case "user_cnpj" :
        return "PixCNPJ";
    case "user_country" :
        return "Country";
    case "user_cpf" :
        return "PixCPF";
    case "user_crypto_currency_network" :
        return "CryptoCurrencyNetworks";
    case "user_date_of_birth" :
        return "DateOfBirth";
    case "user_email_address" :
        return "Email";
    case "user_full_name" :
        return "FullName";
    case "user_bank_account_number" :
    case "user_iban" :
        return "BankAccountNumber";
    case "user_phone_number" :
        return "PhoneNumber";
    case "user_phone_number_country_code" :
        return "PhoneCountryCode";
    case "user_pix_key" :
        return "PixKey";
    case "user_shipping_address_city" :
        return "ShippingAddressCity";
    case "user_shipping_address_line1" :
        return "ShippingAddressLine1";
    case "user_shipping_address_line2" :
        return "ShippingAddressLine2";
    case "user_shipping_address_pincode" :
        return "ShippingAddressPincode";
    case "user_shipping_address_state" :
        return "ShippingAddressState";
    case "user_shipping_name" :
        return "ShippingName";
    case "user_vpa_id" :
        return "VpaId";
    default:
      return "None";
  }
}

function getOptionsFromPaymentMethodFieldType(dict, key, isAddressCountryOpt) {
  var isAddressCountry = isAddressCountryOpt !== undefined ? isAddressCountryOpt : true;
  var options = Utils$OrcaPaymentPage.getArrayValFromJsonDict(dict, key, "options");
  var match = Core__Option.getOr(options[0], "");
  switch (match) {
    case "" :
        return "None";
    case "ALL" :
        var countryArr = Country$OrcaPaymentPage.country.map(function (item) {
              return item.countryName;
            });
        if (isAddressCountry) {
          return {
                  TAG: "AddressCountry",
                  _0: countryArr
                };
        } else {
          return {
                  TAG: "ShippingAddressCountry",
                  _0: countryArr
                };
        }
    default:
      var countryArr$1 = Core__Array.reduce(Country$OrcaPaymentPage.country, [], (function (acc, country) {
              if (options.includes(country.isoAlpha2)) {
                acc.push(country.countryName);
              }
              return acc;
            }));
      if (isAddressCountry) {
        return {
                TAG: "AddressCountry",
                _0: countryArr$1
              };
      } else {
        return {
                TAG: "ShippingAddressCountry",
                _0: countryArr$1
              };
      }
  }
}

function getPaymentMethodsFieldTypeFromDict(dict) {
  var keysArr = Object.keys(dict);
  var key = Core__Option.getOr(keysArr[0], "");
  switch (key) {
    case "language_preference" :
        var options = Utils$OrcaPaymentPage.getArrayValFromJsonDict(dict, "language_preference", "options");
        return {
                TAG: "LanguagePreference",
                _0: options
              };
    case "user_address_country" :
        return getOptionsFromPaymentMethodFieldType(dict, "user_address_country", undefined);
    case "user_country" :
        return getOptionsFromPaymentMethodFieldType(dict, "user_country", undefined);
    case "user_currency" :
        var options$1 = Utils$OrcaPaymentPage.getArrayValFromJsonDict(dict, "user_currency", "options");
        return {
                TAG: "Currency",
                _0: options$1
              };
    case "user_shipping_address_country" :
        return getOptionsFromPaymentMethodFieldType(dict, "user_shipping_address_country", false);
    default:
      return "None";
  }
}

function getFieldType(dict, isBancontact) {
  var fieldClass = Core__JSON.Classify.classify(Core__Option.getOr(dict["field_type"], {}));
  if (typeof fieldClass !== "object") {
    return "None";
  }
  switch (fieldClass.TAG) {
    case "String" :
        return getPaymentMethodsFieldTypeFromString(fieldClass._0, isBancontact);
    case "Object" :
        return getPaymentMethodsFieldTypeFromDict(fieldClass._0);
    case "Bool" :
    case "Number" :
    case "Array" :
        return "None";
    
  }
}

function getIsBillingField(requiredFieldType) {
  if (typeof requiredFieldType === "object") {
    if (requiredFieldType.TAG === "AddressCountry") {
      return true;
    } else {
      return false;
    }
  }
  switch (requiredFieldType) {
    case "AddressLine1" :
    case "AddressLine2" :
    case "AddressCity" :
    case "AddressPincode" :
    case "AddressState" :
        return true;
    default:
      return false;
  }
}

function getIsAnyBillingDetailEmpty(requiredFields) {
  return Core__Array.reduce(requiredFields, false, (function (acc, requiredField) {
                if (getIsBillingField(requiredField.field_type) && requiredField.value === "") {
                  return true;
                } else {
                  return acc;
                }
              }));
}

function getPaymentMethodFields(paymentMethod, requiredFields, isSavedCardFlowOpt, isAllStoredCardsHaveNameOpt) {
  var isSavedCardFlow = isSavedCardFlowOpt !== undefined ? isSavedCardFlowOpt : false;
  var isAllStoredCardsHaveName = isAllStoredCardsHaveNameOpt !== undefined ? isAllStoredCardsHaveNameOpt : false;
  var isAnyBillingDetailEmpty = getIsAnyBillingDetailEmpty(requiredFields);
  var requiredFieldsArr = requiredFields.map(function (requiredField) {
        var isShowBillingField = getIsBillingField(requiredField.field_type) && isAnyBillingDetailEmpty;
        if ((requiredField.value === "" || isShowBillingField) && !(isSavedCardFlow && requiredField.display_name === "card_holder_name" && isAllStoredCardsHaveName)) {
          return requiredField.field_type;
        } else {
          return "None";
        }
      });
  return requiredFieldsArr.concat(Core__Option.getOr(paymentMethodsFields.find(function (x) {
                      return x.paymentMethodName === paymentMethod;
                    }), {
                  paymentMethodName: "",
                  fields: [],
                  icon: Caml_option.some(icon(19, 25, "")),
                  displayName: "",
                  miniIcon: Caml_option.some(icon(19, 25, ""))
                }).fields);
}

function getPaymentDetails(arr) {
  var finalArr = [];
  arr.map(function (item) {
        var optionalVal = paymentMethodsFields.find(function (i) {
              return i.paymentMethodName === item;
            });
        if (optionalVal !== undefined) {
          finalArr.push(optionalVal);
          return ;
        }
        
      });
  return finalArr;
}

var defaultCardNetworks_eligible_connectors = [];

var defaultCardNetworks = {
  card_network: "NOTFOUND",
  eligible_connectors: defaultCardNetworks_eligible_connectors,
  surcharge_details: undefined
};

var defaultMethods_payment_method_types = [];

var defaultMethods = {
  payment_method: "card",
  payment_method_types: defaultMethods_payment_method_types
};

var defaultPaymentMethodType_payment_experience = [];

var defaultPaymentMethodType_card_networks = [];

var defaultPaymentMethodType_bank_names = [];

var defaultPaymentMethodType_bank_debits_connectors = [];

var defaultPaymentMethodType_bank_transfers_connectors = [];

var defaultPaymentMethodType_required_fields = [];

var defaultPaymentMethodType = {
  payment_method_type: "",
  payment_experience: defaultPaymentMethodType_payment_experience,
  card_networks: defaultPaymentMethodType_card_networks,
  bank_names: defaultPaymentMethodType_bank_names,
  bank_debits_connectors: defaultPaymentMethodType_bank_debits_connectors,
  bank_transfers_connectors: defaultPaymentMethodType_bank_transfers_connectors,
  required_fields: defaultPaymentMethodType_required_fields,
  surcharge_details: undefined,
  pm_auth_connector: undefined
};

var defaultList_payment_methods = [];

var defaultList = {
  redirect_url: "",
  currency: "",
  payment_methods: defaultList_payment_methods,
  mandate_payment: undefined,
  payment_type: "NONE",
  merchant_name: "",
  collect_billing_details_from_wallets: true,
  is_tax_calculation_enabled: false
};

function getPaymentExperienceType(str) {
  switch (str) {
    case "display_qr_code" :
        return "QrFlow";
    case "invoke_sdk_client" :
        return "InvokeSDK";
    default:
      return "RedirectToURL";
  }
}

function getPaymentExperience(dict, str) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
              return {
                      payment_experience_type: getPaymentExperienceType(Utils$OrcaPaymentPage.getString(json, "payment_experience_type", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors")
                    };
            });
}

function getSurchargeDetails(dict) {
  var surchargDetails = Core__Option.getOr(Core__Option.flatMap(dict["surcharge_details"], Core__JSON.Decode.object), {});
  var displayTotalSurchargeAmount = Core__Option.getOr(Core__Option.flatMap(surchargDetails["display_total_surcharge_amount"], Core__JSON.Decode.$$float), 0.0);
  if (displayTotalSurchargeAmount !== 0.0) {
    return {
            displayTotalSurchargeAmount: displayTotalSurchargeAmount
          };
  }
  
}

function getCardNetworks(dict, str) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
              return {
                      card_network: CardUtils$OrcaPaymentPage.getCardType(Utils$OrcaPaymentPage.getString(json, "card_network", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors"),
                      surcharge_details: getSurchargeDetails(json)
                    };
            });
}

function getBankNames(dict, str) {
  return Core__Array.reduce(Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
                  return Utils$OrcaPaymentPage.getStrArray(json, "bank_name");
                }), [], (function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                    });
                return acc;
              }));
}

function getAchConnectors(dict, str) {
  return Utils$OrcaPaymentPage.getStrArray(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), {}), "elligible_connectors");
}

function getDynamicFieldsFromJsonDict(dict, isBancontact) {
  var requiredFields = Object.values(Utils$OrcaPaymentPage.getDictFromJson(Utils$OrcaPaymentPage.getJsonFromDict(dict, "required_fields", null)));
  return requiredFields.map(function (requiredField) {
              var requiredFieldsDict = Utils$OrcaPaymentPage.getDictFromJson(requiredField);
              return {
                      required_field: Utils$OrcaPaymentPage.getString(requiredFieldsDict, "required_field", ""),
                      display_name: Utils$OrcaPaymentPage.getString(requiredFieldsDict, "display_name", ""),
                      field_type: getFieldType(requiredFieldsDict, isBancontact),
                      value: Utils$OrcaPaymentPage.getString(requiredFieldsDict, "value", "")
                    };
            });
}

function getPaymentMethodTypes(dict, str) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (jsonDict) {
              var paymentMethodType = Utils$OrcaPaymentPage.getString(jsonDict, "payment_method_type", "");
              return {
                      payment_method_type: paymentMethodType,
                      payment_experience: getPaymentExperience(jsonDict, "payment_experience"),
                      card_networks: getCardNetworks(jsonDict, "card_networks"),
                      bank_names: getBankNames(jsonDict, "bank_names"),
                      bank_debits_connectors: getAchConnectors(jsonDict, "bank_debit"),
                      bank_transfers_connectors: getAchConnectors(jsonDict, "bank_transfer"),
                      required_fields: getDynamicFieldsFromJsonDict(jsonDict, paymentMethodType === "bancontact_card"),
                      surcharge_details: getSurchargeDetails(jsonDict),
                      pm_auth_connector: Utils$OrcaPaymentPage.getOptionString(jsonDict, "pm_auth_connector")
                    };
            });
}

function getMethodsArr(dict, str) {
  return Belt_Array.keepMap(Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []), Core__JSON.Decode.object).map(function (json) {
              return {
                      payment_method: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                      payment_method_types: getPaymentMethodTypes(json, "payment_method_types")
                    };
            });
}

function getOptionalMandateType(dict, str) {
  return Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                return {
                        amount: Utils$OrcaPaymentPage.getInt(json, "amount", 0),
                        currency: Utils$OrcaPaymentPage.getString(json, "currency", "")
                      };
              }));
}

function getMandate(dict, str) {
  return Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                return {
                        single_use: getOptionalMandateType(json, "single_use"),
                        multi_use: getOptionalMandateType(json, "multi_use")
                      };
              }));
}

function paymentTypeMapper(payment_type) {
  switch (payment_type) {
    case "new_mandate" :
        return "NEW_MANDATE";
    case "normal" :
        return "NORMAL";
    case "setup_mandate" :
        return "SETUP_MANDATE";
    default:
      return "NONE";
  }
}

function paymentTypeToStringMapper(payment_type) {
  switch (payment_type) {
    case "NORMAL" :
        return "normal";
    case "NEW_MANDATE" :
        return "new_mandate";
    case "SETUP_MANDATE" :
        return "setup_mandate";
    case "NONE" :
        return "";
    
  }
}

function itemToObjMapper(dict) {
  return {
          redirect_url: Utils$OrcaPaymentPage.getString(dict, "redirect_url", ""),
          currency: Utils$OrcaPaymentPage.getString(dict, "currency", ""),
          payment_methods: getMethodsArr(dict, "payment_methods"),
          mandate_payment: getMandate(dict, "mandate_payment"),
          payment_type: paymentTypeMapper(Utils$OrcaPaymentPage.getString(dict, "payment_type", "")),
          merchant_name: Utils$OrcaPaymentPage.getString(dict, "merchant_name", ""),
          collect_billing_details_from_wallets: Utils$OrcaPaymentPage.getBool(dict, "collect_billing_details_from_wallets", true),
          is_tax_calculation_enabled: Utils$OrcaPaymentPage.getBool(dict, "is_tax_calculation_enabled", false)
        };
}

function buildFromPaymentList(plist) {
  var paymentMethodArr = plist.payment_methods;
  return Core__Array.reduce(paymentMethodArr.map(function (paymentMethodObject) {
                  var methodType = paymentMethodObject.payment_method;
                  var handleUserError = methodType === "wallet";
                  return paymentMethodObject.payment_method_types.map(function (individualPaymentMethod) {
                              var paymentMethodName = individualPaymentMethod.payment_method_type;
                              var bankNames = individualPaymentMethod.bank_names;
                              var paymentExperience = individualPaymentMethod.payment_experience.map(function (experience) {
                                    return [
                                            experience.payment_experience_type,
                                            experience.eligible_connectors
                                          ];
                                  });
                              return {
                                      paymentMethodName: paymentMethodName,
                                      fields: getPaymentMethodFields(paymentMethodName, individualPaymentMethod.required_fields, undefined, undefined),
                                      paymentFlow: paymentExperience,
                                      handleUserError: handleUserError,
                                      methodType: methodType,
                                      bankNames: bankNames
                                    };
                            });
                }), [], (function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                    });
                return acc;
              }));
}

function getPaymentMethodTypeFromList(paymentMethodListValue, paymentMethod, paymentMethodType) {
  return Core__Option.getOr(paymentMethodListValue.payment_methods.find(function (item) {
                    return item.payment_method === paymentMethod;
                  }), defaultMethods).payment_method_types.find(function (item) {
              return item.payment_method_type === paymentMethodType;
            });
}

function getCardNetwork(paymentMethodType, cardBrand) {
  return Core__Option.getOr(paymentMethodType.card_networks.filter(function (cardNetwork) {
                    return cardNetwork.card_network === cardBrand;
                  })[0], defaultCardNetworks);
}

function getPaymentExperienceTypeFromPML(paymentMethodList, paymentMethodName, paymentMethodType) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(paymentMethodList.payment_methods.filter(function (paymentMethod) {
                            return paymentMethod.payment_method === paymentMethodName;
                          })[0], (function (method) {
                        return method.payment_method_types.filter(function (methodTypes) {
                                      return methodTypes.payment_method_type === paymentMethodType;
                                    })[0];
                      })), (function (paymentMethodTypes) {
                    return paymentMethodTypes.payment_experience.map(function (paymentExperience) {
                                return paymentExperience.payment_experience_type;
                              });
                  })), []);
}

export {
  getPaymentMethodsFieldsOrder ,
  sortPaymentMethodFields ,
  defaultPaymentFieldsInfo ,
  defaultPaymentMethodContent ,
  defaultPaymentMethodFields ,
  icon ,
  paymentMethodsFields ,
  getPaymentMethodsFieldTypeFromString ,
  getOptionsFromPaymentMethodFieldType ,
  getPaymentMethodsFieldTypeFromDict ,
  getFieldType ,
  getIsBillingField ,
  getIsAnyBillingDetailEmpty ,
  getPaymentMethodFields ,
  getPaymentDetails ,
  defaultCardNetworks ,
  defaultMethods ,
  defaultPaymentMethodType ,
  defaultList ,
  getPaymentExperienceType ,
  getPaymentExperience ,
  getSurchargeDetails ,
  getCardNetworks ,
  getBankNames ,
  getAchConnectors ,
  getDynamicFieldsFromJsonDict ,
  getPaymentMethodTypes ,
  getMethodsArr ,
  getOptionalMandateType ,
  getMandate ,
  paymentTypeMapper ,
  paymentTypeToStringMapper ,
  itemToObjMapper ,
  buildFromPaymentList ,
  getPaymentMethodTypeFromList ,
  getCardNetwork ,
  getPaymentExperienceTypeFromPML ,
}
/* paymentMethodsFields Not a pure module */
