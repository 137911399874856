// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultToken_allowed_payment_methods = [];

var defaultToken_transaction_info = {};

var defaultToken_merchant_info = {};

var defaultToken_shippingAddressParameters = {};

var defaultToken_orderDetails = {};

var defaultToken = {
  walletName: "NONE",
  token: "",
  sessionId: "",
  allowed_payment_methods: defaultToken_allowed_payment_methods,
  transaction_info: defaultToken_transaction_info,
  merchant_info: defaultToken_merchant_info,
  shippingAddressRequired: false,
  emailRequired: false,
  shippingAddressParameters: defaultToken_shippingAddressParameters,
  orderDetails: defaultToken_orderDetails,
  connector: "",
  clientId: "",
  clientName: "",
  clientProfileId: "",
  email_address: "",
  transaction_amount: "",
  transaction_currency_code: ""
};

function getWallet(str) {
  switch (str) {
    case "apple_pay" :
        return "ApplePay";
    case "click_to_pay" :
        return "ClickToPay";
    case "google_pay" :
        return "Gpay";
    case "klarna" :
        return "Klarna";
    case "paypal" :
        return "Paypal";
    case "paze" :
        return "Paze";
    case "samsung_pay" :
        return "SamsungPay";
    default:
      return "NONE";
  }
}

function getSessionsToken(dict, str) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), (function (arr) {
                    return arr.map(function (json) {
                                var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                                return {
                                        walletName: getWallet(Utils$OrcaPaymentPage.getString(dict, "wallet_name", "")),
                                        token: Utils$OrcaPaymentPage.getString(dict, "session_token", ""),
                                        sessionId: Utils$OrcaPaymentPage.getString(dict, "session_id", ""),
                                        allowed_payment_methods: Utils$OrcaPaymentPage.getArray(dict, "allowed_payment_methods"),
                                        transaction_info: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "transaction_info"),
                                        merchant_info: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "merchant_info"),
                                        shippingAddressRequired: Utils$OrcaPaymentPage.getBool(dict, "shipping_address_required", false),
                                        emailRequired: Utils$OrcaPaymentPage.getBool(dict, "email_required", false),
                                        shippingAddressParameters: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "shipping_address_parameters"),
                                        orderDetails: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "order_details"),
                                        connector: Utils$OrcaPaymentPage.getString(dict, "connector", ""),
                                        clientId: Utils$OrcaPaymentPage.getString(dict, "client_id", ""),
                                        clientName: Utils$OrcaPaymentPage.getString(dict, "client_name", ""),
                                        clientProfileId: Utils$OrcaPaymentPage.getString(dict, "client_profile_id", ""),
                                        email_address: Utils$OrcaPaymentPage.getString(dict, "email_address", ""),
                                        transaction_amount: Utils$OrcaPaymentPage.getString(dict, "transaction_amount", ""),
                                        transaction_currency_code: Utils$OrcaPaymentPage.getString(dict, "transaction_currency_code", "")
                                      };
                              });
                  })), [defaultToken]);
}

function getSessionsTokenJson(dict, str) {
  return Core__Option.getOr(Core__Option.flatMap(dict[str], Core__JSON.Decode.array), []);
}

function itemToObjMapper(dict, returnType) {
  switch (returnType) {
    case "ApplePayObject" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "ApplePayToken",
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case "GooglePayThirdPartyObject" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "GooglePayThirdPartyToken",
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case "SamsungPayObject" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "SamsungPayToken",
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case "PazeObject" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "PazeToken",
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case "ClickToPayObject" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "ClickToPayToken",
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case "Others" :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: "OtherToken",
                  _0: getSessionsToken(dict, "session_token")
                }
              };
    
  }
}

function getWalletFromTokenType(arr, val) {
  return arr.find(function (item) {
              return getWallet(Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(item), (function (x) {
                                        return x["wallet_name"];
                                      })), Core__JSON.Decode.string), "")) === val;
            });
}

function getPaymentSessionObj(tokenType, val) {
  switch (tokenType.TAG) {
    case "ApplePayToken" :
        return {
                TAG: "ApplePayTokenOptional",
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case "GooglePayThirdPartyToken" :
        return {
                TAG: "GooglePayThirdPartyTokenOptional",
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case "PazeToken" :
        return {
                TAG: "PazeTokenOptional",
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case "SamsungPayToken" :
        return {
                TAG: "SamsungPayTokenOptional",
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case "ClickToPayToken" :
        return {
                TAG: "ClickToPayTokenOptional",
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case "OtherToken" :
        return {
                TAG: "OtherTokenOptional",
                _0: tokenType._0.find(function (item) {
                      return item.walletName === val;
                    })
              };
    
  }
}

export {
  defaultToken ,
  getWallet ,
  getSessionsToken ,
  getSessionsTokenJson ,
  itemToObjMapper ,
  getWalletFromTokenType ,
  getPaymentSessionObj ,
}
/* Utils-OrcaPaymentPage Not a pure module */
