// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function useScript(src, typeOpt) {
  var type = typeOpt !== undefined ? typeOpt : "";
  var match = React.useState(function () {
        if (src !== "") {
          return "loading";
        } else {
          return "idle";
        }
      });
  var setStatus = match[1];
  React.useEffect((function () {
          if (src === "") {
            setStatus(function (param) {
                  return "idle";
                });
          }
          var script = document.querySelector("script[src=\"" + src + "\"]");
          if (script == null) {
            var script$1 = document.createElement("script");
            script$1.src = src;
            if (type !== "") {
              script$1.type = type;
            }
            script$1.async = true;
            script$1.setAttribute("data-status", "loading");
            document.body.appendChild(script$1);
            var setAttributeFromEvent = function ($$event) {
              setStatus(function (param) {
                    if ($$event.type === "load") {
                      return "ready";
                    } else {
                      return "error";
                    }
                  });
              script$1.setAttribute("data-status", $$event.type === "load" ? "ready" : "error");
            };
            script$1.addEventListener("load", setAttributeFromEvent);
            script$1.addEventListener("error", setAttributeFromEvent);
            return (function () {
                      script$1.removeEventListener("load", setAttributeFromEvent);
                      script$1.removeEventListener("error", setAttributeFromEvent);
                    });
          }
          setStatus(function (param) {
                return script.getAttribute("data-status");
              });
        }), [src]);
  return match[0];
}

function useLink(src) {
  var match = React.useState(function () {
        if (src !== "") {
          return "loading";
        } else {
          return "idle";
        }
      });
  var setStatus = match[1];
  React.useEffect((function () {
          if (src === "") {
            setStatus(function (param) {
                  return "idle";
                });
          }
          var link = document.querySelector("link[href=\"" + src + "\"]");
          if (link == null) {
            var link$1 = document.createElement("link");
            link$1.href = src;
            link$1.rel = "stylesheet";
            link$1.setAttribute("data-status", "loading");
            document.body.appendChild(link$1);
            var setAttributeFromEvent = function ($$event) {
              setStatus(function (param) {
                    if ($$event.type === "load") {
                      return "ready";
                    } else {
                      return "error";
                    }
                  });
              link$1.setAttribute("data-status", $$event.type === "load" ? "ready" : "error");
            };
            link$1.addEventListener("load", setAttributeFromEvent);
            link$1.addEventListener("error", setAttributeFromEvent);
            return (function () {
                      link$1.removeEventListener("load", setAttributeFromEvent);
                      link$1.removeEventListener("error", setAttributeFromEvent);
                    });
          }
          setStatus(function (param) {
                return link.getAttribute("data-status");
              });
        }), [src]);
  return match[0];
}

function updateKeys(dict, keyPair, setKeys) {
  var value = keyPair[1];
  var key = keyPair[0];
  var valueStr = Utils$OrcaPaymentPage.getStringFromJson(value, "");
  var valueBool = function ($$default) {
    return Core__Option.getOr(Core__JSON.Decode.bool(value), $$default);
  };
  if (!Utils$OrcaPaymentPage.getDictIsSome(dict, key)) {
    return ;
  }
  switch (key) {
    case "iframeId" :
        return setKeys(function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.iframeId = Utils$OrcaPaymentPage.getString(dict, key, valueStr);
                    return newrecord;
                  });
    case "parentURL" :
        return setKeys(function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.parentURL = Utils$OrcaPaymentPage.getString(dict, key, valueStr);
                    return newrecord;
                  });
    case "publishableKey" :
        return setKeys(function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.publishableKey = Utils$OrcaPaymentPage.getString(dict, key, valueStr);
                    return newrecord;
                  });
    case "sdkHandleOneClickConfirmPayment" :
        return setKeys(function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.sdkHandleOneClickConfirmPayment = Utils$OrcaPaymentPage.getBool(dict, key, valueBool(true));
                    return newrecord;
                  });
    default:
      return ;
  }
}

var defaultkeys = {
  publishableKey: "",
  iframeId: "",
  parentURL: "*",
  sdkHandleOneClickConfirmPayment: true
};

export {
  useScript ,
  useLink ,
  updateKeys ,
  defaultkeys ,
}
/* react Not a pure module */
