// Generated by ReScript, PLEASE EDIT WITH CARE

import PackageJson from "/package.json";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as GlobalVars$OrcaPaymentPage from "./GlobalVars.bs.js";

var $$Navigator = {};

var $$Location = {};

var $$Location$1 = {};

var Top = {
  $$Location: $$Location$1
};

var LocalStorage = {};

var $$Element = {};

function sendPostMessage(element, message) {
  element.postMessage(JSON.stringify(message), GlobalVars$OrcaPaymentPage.targetOrigin);
}

function sendPostMessageJSON(element, message) {
  element.postMessage(message, GlobalVars$OrcaPaymentPage.targetOrigin);
}

function iframePostMessage(iframeRef, message) {
  if (iframeRef == null) {
    console.error("This element does not exist or is not mounted yet.");
    return ;
  }
  try {
    return sendPostMessage(iframeRef.contentWindow, message);
  }
  catch (exn){
    return ;
  }
}

var version = PackageJson.version;

var hrefWithoutSearch = window.location.origin + window.location.pathname;

var isSandbox = window.location.hostname === "beta.hyperswitch.io";

var isInteg = window.location.hostname === "dev.hyperswitch.io";

var isProd = window.location.hostname === "checkout.hyperswitch.io";

function isIframed() {
  try {
    return window.location.href !== window.top.location.href;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Failed to check whether or not document is within an iframe", e, "Using \"" + String(true) + "\" as default (due to DOMException)");
    return true;
  }
}

function getRootHostName() {
  if (!isIframed()) {
    return window.location.hostname;
  }
  try {
    return window.top.location.hostname;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    var $$default = window.location.hostname;
    console.error("Failed to get root document's hostname", e, "Using \"" + $$default + "\" [window.location.hostname] as default");
    return $$default;
  }
}

export {
  $$Navigator ,
  $$Location ,
  Top ,
  LocalStorage ,
  $$Element ,
  sendPostMessage ,
  sendPostMessageJSON ,
  iframePostMessage ,
  version ,
  hrefWithoutSearch ,
  isSandbox ,
  isInteg ,
  isProd ,
  isIframed ,
  getRootHostName ,
}
/* version Not a pure module */
