// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function decodeRedirectionFlags(json, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(Core__JSON.Decode.object(json), (function (obj) {
                    var shouldUseTopRedirection = Core__Option.getOr(Core__Option.flatMap(obj["shouldUseTopRedirection"], Core__JSON.Decode.bool), $$default.shouldUseTopRedirection);
                    var shouldRemoveBeforeUnloadEvents = Core__Option.getOr(Core__Option.flatMap(obj["shouldRemoveBeforeUnloadEvents"], Core__JSON.Decode.bool), $$default.shouldRemoveBeforeUnloadEvents);
                    return {
                            shouldUseTopRedirection: shouldUseTopRedirection,
                            shouldRemoveBeforeUnloadEvents: shouldRemoveBeforeUnloadEvents
                          };
                  })), $$default);
}

export {
  decodeRedirectionFlags ,
}
/* No side effect */
