// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Hyper$OrcaPaymentPage from "./Hyper.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as HyperLogger$OrcaPaymentPage from "../hyper-log-catcher/HyperLogger.bs.js";

function loadHyper(str, option) {
  return Promise.resolve(Hyper$OrcaPaymentPage.make(str, option, undefined));
}

function loadStripe(str, option) {
  ErrorUtils$OrcaPaymentPage.manageErrorWarning("DEPRECATED_LOADSTRIPE", undefined, HyperLogger$OrcaPaymentPage.defaultLoggerConfig);
  return loadHyper(str, option);
}

function removeBeforeUnloadEventListeners(handlers) {
  var iframeMessageHandler = function (ev) {
    var dict = Utils$OrcaPaymentPage.getDictFromJson(ev.data);
    Core__Option.map(dict["disableBeforeUnloadEventListener"], (function (shouldRemove) {
            if (!Core__Option.getOr(Core__JSON.Decode.bool(shouldRemove), false)) {
              return ;
            }
            try {
              handlers.map(function (handler) {
                    window.removeEventListener("beforeunload", handler);
                  });
              return ;
            }
            catch (raw_err){
              var err = Caml_js_exceptions.internalToOCamlException(raw_err);
              console.error("Incorrect usage of removeBeforeUnloadEventListeners hook", err);
              return ;
            }
          }));
  };
  window.addEventListener("message", iframeMessageHandler);
}

window.Hyper = Hyper$OrcaPaymentPage.make;

window.Hyper.init = Hyper$OrcaPaymentPage.make;

window.removeBeforeUnloadEventListeners = removeBeforeUnloadEventListeners;

var isWordpress = window.wp !== null;

if (!isWordpress) {
  window.Stripe = Hyper$OrcaPaymentPage.make;
}

export {
  loadHyper ,
  loadStripe ,
  removeBeforeUnloadEventListeners ,
  isWordpress ,
}
/*  Not a pure module */
