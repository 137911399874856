// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UaParserJs from "./ua-parser.js";

function make(prim) {
  return UaParserJs.UAParser();
}

export {
  make ,
}
/* ./ua-parser.js Not a pure module */
